<template>
  <div class="sidebar-links block block--white block--rounded">
    <div class="block__title">
      Настройки левого меню
    </div>
    <draggable
      :list="localSidebarLinks"
      group="sidebarLinks"
      class="block__row sidebar-links__list"
      @change="changeSortOrder"
    >
      <div
        class="block__col"
        v-for="(button, index) in sidebarLinks"
        :key="button.type"
      >
        <div
          class="sidebar-links__item"
        >
          <esmp-button
            class="sidebar-links__example"
            icon-position="left"
            :icon="button.icon"
            view="function"
          >
            {{ button.label }}
          </esmp-button>
          <div class="sidebar-links__settings">
            <esmp-input
              :value="button.label"
              label="Введите название"
              @input="setValue('label', index, $event)"
            />
            <esmp-checkbox
              :value="button.visibility"
              @input="setValue('visibility', index, $event)"
            >
              Видимость
            </esmp-checkbox>
          </div>
        </div>
      </div>
    </draggable>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('portalMenu')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import draggable from 'vuedraggable';
import Hub from '@/plugins/event-hub';

export default {
  name: 'SidebarLinks',
  components: {
    draggable,
  },
  data() {
    return {
      localSidebarLinks: [],
    };
  },
  computed: {
    ...mapState('portalStyle', ['settings']),
    sidebarLinks: {
      get() {
        return this.settings.sidebarMenu.portalMenu;
      },
      set(newValue) {
        this.setSetting({
          path: ['sidebarMenu.portalMenu'],
          value: newValue,
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'sidebarMenu', settingList: ['portalMenu'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки левого меню успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    setValue(fieldName, index, newValue) {
      const sidebarLinks = cloneDeep(this.sidebarLinks);
      sidebarLinks[index][fieldName] = newValue;
      this.sidebarLinks = sidebarLinks;
    },
    changeSortOrder() {
      this.sidebarLinks = this.localSidebarLinks.map((link, index) => ({
        ...link,
        order: index,
      }));
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
  created() {
    this.localSidebarLinks = [...this.settings.sidebarMenu.portalMenu];
  },
};
</script>

<style lang="scss">
.sidebar-links {
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: grab;
  }

  &__example {
    margin-bottom: $base-gutter;
  }

  &__settings {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $base-gutter;
    position: relative;
  }
}
</style>
